import React from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';

import HomePage from './HomePage';
import SupportPage from './SupportPage';

import * as routes from '../constants/routes';

import '../constants/root.css';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={routes.HOME} element={<HomePage />} />
                <Route path={routes.SUPPORT} element={<SupportPage />} />
                <Route
                    path='*'
                    element={<Navigate replace to={routes.HOME} />}
                />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
