import React, { useState, useEffect } from 'react';

import { registerQuestion } from '../../utils/firebase';

import CategoryImage1 from '../../assets/category-capitalism.png';
import CategoryImage2 from '../../assets/category-technology.png';
import CategoryImage3 from '../../assets/category-tendences.png';
import CategoryImage4 from '../../assets/category-labor.png';
import CategoryImage5 from '../../assets/category-social.png';

import './styles.css';
import './animation.css';

const categories = [
    {
        alias: 'capitalism',
        name: 'Capitalismo Consciente',
        image: CategoryImage1,
    },
    {
        alias: 'technology',
        name: 'Tecnologia Humanizada',
        image: CategoryImage2,
    },
    { alias: 'tendences', name: 'Tendências Globais', image: CategoryImage3 },
    { alias: 'labor', name: 'Futuro do Trabalho', image: CategoryImage4 },
    { alias: 'social', name: 'Mudança Social', image: CategoryImage5 },
];

const QuestionModal = ({ category, setCategory, showModal, setShowModal }) => {
    const [question, setQuestion] = useState('');
    const [questionSent, setQuestionSent] = useState(false);

    useEffect(() => {
        !showModal && setCategory(null);
    }, [showModal, setCategory]);

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();

                if (question) {
                    setQuestionSent(true);
                    registerQuestion(
                        categories.filter((c) => c.alias === category)[0].name,
                        question,
                    );

                    setTimeout(() => {
                        setQuestionSent(false);
                        setShowModal(false);
                    }, 3000);
                }
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [category, question, setShowModal]);

    const currentCategory = categories.filter((c) => c.alias === category)[0];

    return (
        <div
            id='kes-question-modal'
            className={questionSent ? 'kes-modal-sent' : ''}
        >
            <div
                id='kes-modal-content'
                className={`kes-modal-category-${currentCategory.alias}`}
            >
                {!questionSent && (
                    <>
                        <button
                            id='kes-modal-return-button'
                            onClick={() => setShowModal(false)}
                        >
                            {'< voltar'}
                        </button>
                        <img
                            id='kes-modal-image'
                            src={currentCategory.image}
                            alt={currentCategory.name}
                        ></img>
                        <span id='kes-modal-text'>
                            Quais as perguntas não respondidas você ainda tem
                            sobre {currentCategory.name.toLowerCase()}?
                        </span>
                        <textarea
                            id='kes-modal-input'
                            placeholder='Sua pergunta aqui...'
                            maxLength={300}
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                        />
                        <button
                            id='kes-modal-send-button'
                            className={!question ? 'kes-button-disabled' : ''}
                            onClick={() => {
                                if (question) {
                                    setQuestionSent(true);
                                    registerQuestion(
                                        currentCategory.name,
                                        question,
                                    );

                                    setTimeout(() => {
                                        setQuestionSent(false);
                                        setShowModal(false);
                                    }, 3000);
                                }
                            }}
                        >
                            compartilhar questão
                        </button>
                    </>
                )}
                {questionSent && (
                    <>
                        <div id='kes-modal-sent-message'>
                            <span>questão enviada com sucesso</span>
                        </div>
                        <div id='kes-modal-sent-message-square'></div>
                    </>
                )}
            </div>
        </div>
    );
};

export default QuestionModal;
