import React, { useState } from 'react';

import Header from '../../components/Header';
import FloatingCategories from '../../components/FloatingCategories';
import SupportModal from '../../components/SupportModal';
import Footer from '../../components/Footer';

import './styles.css';

const SupportPage = () => {
    const [category, setCategory] = useState(null);
    const [showModal, setShowModal] = useState(false);

    return (
        <div id='kes-support-page'>
            <Header />
            <div id='kes-support-page-intro'>
                Escolha um dos temas abaixo e nos conte como você pode
                colaborar:
            </div>
            <FloatingCategories
                setCategory={setCategory}
                setShowModal={setShowModal}
            />
            {showModal && (
                <SupportModal
                    category={category}
                    setCategory={setCategory}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            )}
            <Footer />
        </div>
    );
};

export default SupportPage;
