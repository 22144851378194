import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

const app = initializeApp(firebaseConfig);
const database = getFirestore(app);

export const registerQuestion = async (category, question) => {
    try {
        const questionDocument = {
            category,
            question,
            printed: false,
            createdAt: new Date().toISOString(),
        };

        const documentCollection = collection(database, 'questions');
        const documentReference = await addDoc(
            documentCollection,
            questionDocument,
        );

        return documentReference.id;
    } catch (e) {
        return null;
    }
};

export const registerSupport = async (category, name, text) => {
    try {
        const supportDocument = {
            category,
            name,
            text,
            createdAt: new Date().toISOString(),
        };

        const documentCollection = collection(database, 'support');
        const documentReference = await addDoc(
            documentCollection,
            supportDocument,
        );

        return documentReference.id;
    } catch (e) {
        return null;
    }
};
