import React from 'react';

import CategoryImage1 from '../../assets/category-capitalism.png';
import CategoryImage2 from '../../assets/category-technology.png';
import CategoryImage3 from '../../assets/category-tendences.png';
import CategoryImage4 from '../../assets/category-labor.png';
import CategoryImage5 from '../../assets/category-social.png';

import './animation.css';
import './styles.css';

const categories = [
    {
        alias: 'capitalism',
        name: 'Capitalismo Consciente',
        image: CategoryImage1,
    },
    {
        alias: 'technology',
        name: 'Tecnologia Humanizada',
        image: CategoryImage2,
    },
    { alias: 'tendences', name: 'Tendências Globais', image: CategoryImage3 },
    { alias: 'labor', name: 'Futuro do Trabalho', image: CategoryImage4 },
    { alias: 'social', name: 'Mudança Social', image: CategoryImage5 },
];

const FloatingCategories = ({ setCategory, setShowModal }) => {
    return (
        <div id='kes-floating-categories'>
            <div id='kes-floating-categories-background'>
                {Array.from({ length: 5 }, (_, i) => (
                    <div key={`circle-${i}`} className={`circle-${i}`} />
                ))}
            </div>
            {categories.map((c) => {
                return (
                    <img
                        id={`category-image-${c.alias}`}
                        key={c.alias}
                        src={c.image}
                        alt={c.name}
                        onClick={() => {
                            setCategory(c.alias);
                            setShowModal(true);
                        }}
                    ></img>
                );
            })}
        </div>
    );
};

export default FloatingCategories;
