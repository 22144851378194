import React from 'react';

import OddLogo from '../../assets/odd-logo.png';

import './styles.css';

const Footer = () => {
    return (
        <footer id='kes-page-footer'>
            <a href='https://odd.studio/' target='_blank' rel='noreferrer'>
                <div id='odd-logo-wrapper'>
                    <img
                        id='odd-logo-image'
                        src={OddLogo}
                        alt='An experiment by Odd.Studio'
                    ></img>
                    <span id='odd-logo-text'>An experiment by Odd.Studio</span>
                </div>
            </a>
        </footer>
    );
};

export default Footer;
