import React from 'react';

import KESLogo from '../../assets/kes-summit-logo.png';

import './styles.css';

const Header = () => {
    return (
        <header id='kes-page-header'>
            <div id='kes-header-overlay'>
                <img
                    id='kes-summit-logo'
                    src={KESLogo}
                    alt='KES Summit: Untold Futures'
                ></img>
            </div>
        </header>
    );
};

export default Header;
