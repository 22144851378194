import React, { useState } from 'react';

import Header from '../../components/Header';
import FloatingCategories from '../../components/FloatingCategories';
import QuestionModal from '../../components/QuestionModal';
import Footer from '../../components/Footer';

import './styles.css';

const HomePage = () => {
    const [category, setCategory] = useState(null);
    const [showModal, setShowModal] = useState(false);

    return (
        <div id='kes-home-page'>
            <Header />
            <div id='kes-home-page-intro'>
                Escolha um dos temas abaixo e compartilhe a sua pergunta:
            </div>
            <FloatingCategories
                setCategory={setCategory}
                setShowModal={setShowModal}
            />
            {showModal && (
                <QuestionModal
                    category={category}
                    setCategory={setCategory}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            )}
            <Footer />
        </div>
    );
};

export default HomePage;
